import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/english.scss"
const English = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsEnglishpage?.seoMetaTags} page="English">
    <div className="english">
      <div className="content">
        <div
          dangerouslySetInnerHTML={{
            __html: data.datoCmsEnglishpage.bodyNode.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  </Layout>
)
export const query = graphql`
  query EnglishQuery {
    datoCmsEnglishpage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default English
